import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import KnockoutPage, { KnockoutPageData } from 'templates/KnockoutPage';

export const query = graphql`
  query {
    csKnockoutPage(page_id: { eq: "temporary-driver-under-twenty-one-different-address" }) {
      ...KnockoutPage
    }
  }
`;

const DriverUnderTwentyOneDifferentAddress: React.FC = () => {
  const data = useStaticQuery<KnockoutPageData>(query);
  return <KnockoutPage data={data} />;
};

export default DriverUnderTwentyOneDifferentAddress;
